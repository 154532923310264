.banner-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.banner-container a img {
  max-width: 100px;
  height: auto;
}

.banner-container div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.tags{
  background: #333;                 /* Dark background for tags */
  border-radius: 3px;               /* Slightly rounded corners */
  border: solid 1px #555;           /* Border with a subtle contrast */
  color: #ccc;                      /* Light text color for contrast */
  height: 26px;                     /* Fixed height */
  line-height: 26px;                /* Center text vertically within the height */
  padding: 0 10px;                  /* Horizontal padding inside tags */
  margin: 4px 8px;                 /* Margin around tags, adjusted for top, bottom, right, and left */
  text-decoration: none;            /* Remove underline from links */
  display: inline-block;            /* Treat tags as inline blocks */
  font-size: 12px;                  /* Set font size */
  display: inline-flex;  /* Use inline-flex for alignment */
  align-items: center;  /* Center content vertically */
  justify-content: center;  /* Center content horizontally */
  text-align: center;  /* Center text inside the tag */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.read-more-btn {
  background-color: #ff9900;
  border-radius: 3px;
  height: 26px;                     /* Fixed height */
  line-height: 26px;                /* Center text vertically within the height */
  padding: 0 10px;                  /* Horizontal padding inside tags */
  margin: 4px 8px;                 /* Margin around tags, adjusted for top, bottom, right, and left */
  text-decoration: none;            /* Remove underline from links */
  display: inline-block;            /* Treat tags as inline blocks */
  font-size: 12px;                  /* Set font size */
  color: black;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
